import React, { useState, useEffect } from 'react';

const Footer = ({ t }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const pageStyle = {
    backgroundColor: '#d03142',
    color: 'white',
    padding: '20px 0',
  };

  const centerStyle = {
    display: 'flex',
    flexDirection: isMobile ? 'column' : 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px 0',
    paddingBottom: '40px',
    ...(isMobile ? { marginBottom: '40px' } : {}),
  };

  return (
    <footer style={pageStyle} aria-label="Footer">
      <nav style={centerStyle} aria-label="Footer navigation">
        <a href="https://arana.app/imprint" style={{ color: 'white', textDecoration: 'none', margin: '20px' }} target="_blank" rel="noopener noreferrer" aria-label={t('Imprint')}>{t('Imprint')}</a>
        <a href="https://arana.app/terms" style={{ color: 'white', textDecoration: 'none', margin: '20px' }} target="_blank" rel="noopener noreferrer" aria-label={t('Terms and Conditions')}>{t('Terms and Conditions')}</a>
        <a href="https://arana.app/privacy-policy" style={{ color: 'white', textDecoration: 'none', margin: '20px' }} target="_blank" rel="noopener noreferrer" aria-label={t('Privacy Policy')}>{t('Privacy Policy')}</a>
      </nav>
    </footer>
  );
};

export default Footer;