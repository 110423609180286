function LoadingComponent({ t, i18n }) {
  const loadingStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: '#d03142',
  };

  return (
    <div style={loadingStyle} aria-busy="true" aria-live="polite">
      <div className="loadingCircle" role="alert" aria-label={t('Loading')}></div> {/* Circular Loading Animation */}
    </div>
  );
}

export default LoadingComponent;
