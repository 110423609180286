import React, { useEffect } from 'react';

import SharedLayout from './SharedLayout';
import AranaButton from './AranaButton';
import BuyButtonComponent from './BuyButtonComponent';

const Home = ({ t, i18n, publishableKey }) => {

  const centerStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#d03142',
  };

  const subscriptionOptionsStyle = {
    backgroundColor: '#ffffff',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px 0',
    margin: '40px 2px 20px 2px',
    width: '100%',
  };

  return (
    <SharedLayout t={t} setHeight={true}>
      <div style={subscriptionOptionsStyle} role="alert" aria-live="polite">
      <BuyButtonComponent
          subscriptionType="basic"
          publishableKey={publishableKey}
          t={t}
          i18n={i18n}
        />
        <BuyButtonComponent
          subscriptionType="premium"
          publishableKey={publishableKey}
          t={t}
          i18n={i18n}
        />
        <BuyButtonComponent
          subscriptionType="unlimited"
          publishableKey={publishableKey}
          t={t}
          i18n={i18n}
        />
      </div>
      <div style={centerStyle}>
      <AranaButton
      t={t}
      i18n={i18n}
      buttonText={t('Start Arana Assistant')}
      ariaLabel={t('Start Arana Assistant')}
      onClick={() => window.location.href='http://arana.link/assistant'}
      width={'400px'}
      />
      </div>
    </SharedLayout>
  );
}

export default Home;