import { useMemo } from 'react';

import {
  Elements,
  // ExpressCheckoutElement
} from '@stripe/react-stripe-js';
import CheckoutForm from './CheckoutForm';
// import ExpressCheckoutForm from './ExpressCheckoutForm';
import SharedLayout from './SharedLayout';

const Payment = ({ clientSecret, stripePromise, sessionData, t, i18n }) => {
  const options = useMemo(() => ({
    mode: 'subscription',
    amount: sessionData.subscriptionInfo.amount,
    currency: sessionData.subscriptionInfo.currency,
    setup_future_usage: 'off_session',
    payment_method_types: [sessionData.sessionInfo.paymentMethod],
  }), [sessionData]);

  return (
    <SharedLayout t={t} setHeight={false}>
      {clientSecret && stripePromise && (
        <Elements
          stripe={stripePromise}
          options={options}
          clientSecret={clientSecret}
        >
          <CheckoutForm
            clientSecret={clientSecret}
            sessionData={sessionData}
            t={t}
            i18n={i18n}
          />
          {/* <ExpressCheckoutForm /> */}
        </Elements>
      )}
      {/* Additional content and styling as needed */}
    </SharedLayout>
  );
}

export default Payment;