import React, { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

import SharedLayout from './SharedLayout';
import LoadingComponent from './LoadingComponent';
import AranaButton from './AranaButton';

const CustomerInfo = ({ fetchSessionData, sessionData, setClientSecret, publishableKey, t, i18n }) => {
  let [searchParams] = useSearchParams();
  const sessionToken = searchParams.get('sessionToken');
  let navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    console.log('Fetching customer data in useEffect...');
    if (sessionToken) {
      console.log('Session token found.');
      fetchSessionData(sessionToken)
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [sessionToken, fetchSessionData, setIsLoading]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const sectionStyle = {
    margin: '0 auto',
    ...(isMobile ? { width: '70%' } : { width: '400px'}),
    textAlign: 'center',
    padding: '0px 20px 20px 20px',
    backgroundColor: '#ffffff',
    borderRadius: '6px',
    boxShadow: '0 10px 15px 0 rgba(0, 0, 0, 0.2)', // subtle shadow
  };

  if (!sessionToken) {
    console.log('No session token found.');
    return (
      <SharedLayout t={t} setHeight={true}><div style={{ color: '#fff' }}>{t('Invalid session.')}</div></SharedLayout>
    );
  }

  // Render 'Invalid session' message if data is null
  if (!sessionData) {
    return <LoadingComponent t={t} i18n={i18n} />;
  }

  let subscriptionType = sessionData.sessionInfo.subscriptionType; // Assuming sessionData is available
  const paymentCycleTerm = sessionData.sessionInfo.paymentCycle === 'monthly' ? t('monthly') : t('yearly');

  const formattedAmount = new Intl.NumberFormat(i18n.language, {
    style: 'currency',
    currency: sessionData.subscriptionInfo.currency.toUpperCase(),
  }).format(sessionData.subscriptionInfo.amount / 100);

  // Check if the string is not empty
  if (subscriptionType) {
    subscriptionType = subscriptionType.charAt(0).toUpperCase() + subscriptionType.slice(1);
    // Now, firstLetterUpperCase contains the string with the first letter in uppercase
  }

  if (isLoading) {
    return (
      <LoadingComponent
        t={t}
        i18n={i18n}
      />
    );
  }

  return (
    <SharedLayout t={t} setHeight={true}>
      <section aria-labelledby="customer-details" style={sectionStyle}>
        <h2 id="customer-details">{t('Customer Details')}</h2>
        <dl
          className="customer-list"
          style={{ textAlign: 'center' }}
          aria-describedby="customer-details"
        >
          <dt className="customer-term">{t('Name :')}</dt>
          <dd className="customer-detail">{sessionData.sessionInfo.fullName}</dd>

          <dt className="customer-term">{t('Country :')}</dt>
          <dd className="customer-detail">{sessionData.sessionInfo.country}</dd>

          <dt className="customer-term">{t('State :')}</dt>
          <dd className="customer-detail">{sessionData.sessionInfo.state}</dd>

          <dt className="customer-term">{t('City :')}</dt>
          <dd className="customer-detail">{sessionData.sessionInfo.city}</dd>

          <dt className="customer-term">{t('Postal code :')}</dt>
          <dd className="customer-detail">{sessionData.sessionInfo.zip}</dd>

          <dt className="customer-term">{t('Street :')}</dt>
          <dd className="customer-detail">{sessionData.sessionInfo.street}</dd>

          <dt className="customer-term">{t('Email :')}</dt>
          <dd className="customer-detail">{sessionData.sessionInfo.email}</dd>

          <dt className="customer-term">{t('Phone :')}</dt>
          <dd className="customer-detail">{sessionData.sessionInfo.phoneNumber}</dd>
        </dl>
      </section>
      <br></br>
      <section aria-labelledby="subscription-details" style={sectionStyle}>
        <h2 id="subscription-details">{t('Subscription details')}</h2>
        <dl
          className="customer-list"
          aria-describedby="subscription-details"
        >
          <dt className="customer-term">{t('Subscription')}:</dt>
          <dd className="customer-detail">
            {subscriptionType}
          </dd>

          <dt className="customer-term">{t('Payment cycle')}:</dt>
          <dd className="customer-detail">{paymentCycleTerm}</dd>

          <dt className="customer-term">{t('Payment amount')}:</dt>
          <dd className="customer-detail">{formattedAmount}</dd>

          <dt className="customer-term">{t('Cancellation terms')}:</dt>
          <dd className="customer-detail">{t('Cancel anytime')}</dd>
        </dl>
      </section>
      <AranaButton
        t={t}
        i18n={i18n}
        buttonText={t('Subscribe and proceed to payment')}
        ariaLabel={t('Subscribe and proceed to payment')}
        onClick={() => handlePaymentConfirmation(navigate, sessionData, setClientSecret)}
        width={isMobile ? '70%' : '400px'}
      />
    </SharedLayout>
  );
};

const handlePaymentConfirmation = async (navigate, sessionData, setClientSecret) => {
  try {
    // Define the API endpoint and data to be sent
    const endpoint = `${process.env.REACT_APP_REST_API_URL}/session`;
    const sessionToken = sessionData.sessionInfo.sessionToken;
    const postData = {
      sessionToken
    };

    // Making the POST request
    const response = await axios.post(endpoint, postData);
    console.log('Response from API:', response.data);

    setClientSecret(response.data.paymentIntentClientSecret)
    sessionStorage.setItem('clientSecret', JSON.stringify(response.data.paymentIntentClientSecret));
      
    // Optional: Navigate to a success page or handle response
    navigate('/payment'); // Adjust the path as needed
  } catch (error) {
    console.error('Error in making POST request:', error);
    // Handle error scenario, maybe show an error message to the user
  }
};

export default CustomerInfo;
