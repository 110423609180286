import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: {
          "Invalid session.": "Invalid session.",
          "Loading": "Loading, please wait...",
          "Arana AI Logo": "Arana AI company logo",
          "Start Arana Assistant": "Start Arana Assistant",
          "Customer Details": "Customer Details",
          "Name :": "Name:",
          "Country :": "Country:",
          "State :": "State:",
          "City :": "City:",
          "Postal code :": "Postal code:",
          "Street :": "Street:",
          "Email :": "Email:",
          "Phone :": "Phone:",
          "Subscription details": "Subscription details",
          "Subscription": "Subscription",
          "Payment method": "Payment method",
          "Enter Payment Details": "Enter Payment Details",
          "IBAN:": "IBAN:",
          "monthly": "Monthly",
          "yearly": "Yearly",
          "Subscribe and proceed to payment": "Subscribe and proceed to payment",
          "Payment details": "Payment details",
          "Please enter your card details below or click on Apple Pay or Google Pay.": "Please enter your card details below or click on Apple Pay or Google Pay.",
          "Please enter your IBAN below. Your other details have been pre-filled.": "Please enter your IBAN below. Your other details have been pre-filled.",
          "Pay now": "Pay now",
          "Stripe Buy Button ID Basic": "buy_btn_1N8LXpHHNHnS3cnwtmMm55PH",
          "Stripe Buy Button ID Premium": "buy_btn_1N8LXpHHNHnS3cnwtmMm55PH",
          "Stripe Buy Button ID Unlimited": "buy_btn_1N8LaaHHNHnS3cnwgQoaT0P8",
          "Thank you for your payment.": "Thank you for your payment.",
          "You will receive a confirmation from Arana Assistant on WhatsApp shortly.": "You will receive a confirmation from Arana Assistant on WhatsApp shortly.",
          "Error: Please contact our support team.": "Error: Please contact our support team.",
          "Payment succeeded! Thank you for your purchase.": "Payment succeeded! Thank you for your purchase.",
          "Payment requires further action. Please follow the instructions you have received in your emails.": "Payment requires further action. Please follow the instructions you have received in your emails.",
          "Processing your payment. Please wait.": "Processing your payment. Please wait.",
          "Cancellation terms": "Cancellation terms",
          "Cancel anytime": "Cancel anytime",
          "Imprint": "Imprint",
          "Privacy Policy": "Privacy Policy",
          "Terms and Conditions": "Terms and Conditions",
          "Thank you!": "Thank you!",
        }
      },
      de: {
        translation: {
          "Invalid session.": "Ungültige Sitzung.",
          "Loading": "Lädt, bitte warten...",
          "Arana AI Logo": "Arana AI Firmenlogo",
          "Start Arana Assistant": "Arana Assistant starten",
          "Customer Details": "Kundendetails",
          "Name :": "Name:",
          "Country :": "Land:",
          "State :": "Bundesland:",
          "City :": "Stadt:",
          "Postal code :": "Postleitzahl:",
          "Street :": "Straße:",
          "Email :": "Email:",
          "Phone :": "Telefon:",
          "Subscription details": "Abonnementdetails",
          "Subscription": "Abonnement",
          "Payment method": "Zahlungsmethode",
          "Enter Payment Details": "Zahlungsdetails eingeben",
          "IBAN:": "IBAN:",
          "monthly": "Monatlich",
          "yearly": "Jährlich",
          "Subscribe and proceed to payment": "Kostenpflichtig abonnieren und im nächsten Schritt bezahlen",
          "Payment details": "Zahlungsdetails",
          "Please enter your card details below or click on Apple Pay or Google Pay.": "Bitte geben Sie Ihre Kartendetails unten ein oder klicken Sie auf Apple Pay oder Google Pay.",
          "Please enter your IBAN below. Your other details have been pre-filled.": "Bitte geben Sie Ihre IBAN unten ein. Ihre anderen Daten wurden bereits ausgefüllt.",
          "Pay now": "Jetzt bezahlen",
          "Stripe Buy Button ID Basic": "buy_btn_1OKNU1HHNHnS3cnw0UjN7G35",
          "Stripe Buy Button ID Premium": "buy_btn_1OKNWCHHNHnS3cnwoiloJ6H8",
          "Stripe Buy Button ID Unlimited": "buy_btn_1OKNYlHHNHnS3cnwNCS5xa4F",
          "Thank you for your payment.": "Vielen Dank für Ihre Zahlung.",
          "You will receive a confirmation from Arana Assistant on WhatsApp shortly.": "Sie erhalten in Kürze eine Bestätigung von Arana Assistant auf WhatsApp.",
          "Error: Please contact our support team.": "Fehler: Bitte kontaktieren Sie unser Support-Team.",
          "Payment succeeded! Thank you for your purchase.": "Zahlung erfolgreich! Vielen Dank für Ihren Kauf.",
          "Payment requires further action. Please follow the instructions you have received in your emails.": "Zahlung erfordert weitere Schritte. Bitte folgen Sie den Anweisungen, die Sie in Ihren E-Mails erhalten haben.",
          "Processing your payment. Please wait.": "Ihre Zahlung wird bearbeitet. Bitte warten.",
          "Payment cycle": "Zahlungszyklus",
          "Payment amount": "Zahlungsbetrag",
          "Cancellation terms": "Stornierungsbedingungen",
          "Cancel anytime": "Jederzeit kündbar",
          "Imprint": "Impressum",
          "Privacy Policy": "Datenschutzerklärung",
          "Terms and Conditions": "AGB",
          "Thank you!": "Vielen Dank!",
        }
      },
      // Add other languages here
    },
    fallbackLng: "en",
    interpolation: { escapeValue: false },
  });

export default i18n;
