import React, { useState } from 'react';

const AranaButton = ({ t, ariaLabel, buttonText, onClick, width }) => {
  const [isHovered, setIsHovered] = useState(false);


  const buttonStyle = {
    backgroundColor: '#ffffff',
    color: '#d03142',
    padding: '15px 32px',
    textAlign: 'center',
    textDecoration: 'none',
    display: 'inline-block',
    fontSize: '20px',
    fontFamily: 'Roboto, sans-serif',
    margin: '40px 2px',
    cursor: 'pointer',
    borderRadius: '6px',
    border: 'none',
    boxShadow: '0 10px 15px 0 rgba(0, 0, 0, 0.2)', // subtle shadow
    transition: 'all 0.3s ease', // smooth transition for hover effect
    width: width || '500px',
    maxWidth: '80%',
  };
  
  const buttonHoverStyle = {
    ...buttonStyle,
    backgroundColor: '#0A3F60', // darker background on hover
    color: '#ffffff',
    boxShadow: '0 8px 16px 0 rgba(0, 0, 0, 0.3)'
  };
  return (
    <button 
      style={isHovered ? buttonHoverStyle : buttonStyle} 
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={onClick}
      aria-label={ariaLabel}
    >{buttonText}</button>
  );
}

export default AranaButton;
