import {useEffect, useState} from 'react';

import SharedLayout from './SharedLayout';
import AranaButton from './AranaButton';

function Completion({ stripePromise, t, i18n }) {
  const [ messageBody, setMessageBody ] = useState('');

  useEffect(() => {
    if (!stripePromise) return;

    stripePromise.then(async (stripe) => {
      const url = new URL(window.location);
      const clientSecret = url.searchParams.get('payment_intent_client_secret');
      const { error, paymentIntent } = await stripe.retrievePaymentIntent(clientSecret);

      if (error) {
        setMessageBody(t('Error: Please contact our support team.'));
      } else {
        switch (paymentIntent.status) {
          case 'succeeded':
            setMessageBody(t('Payment succeeded! Thank you for your purchase.'));
            break;
          case 'requires_action':
            setMessageBody(t('Payment requires further action. Please follow the instructions you have received in your emails.'));
            break;
          // Add more cases as needed for different paymentIntent statuses
          default:
            setMessageBody(t('Processing your payment. Please wait.'));
            break;
        }
      }
    });
  }, [stripePromise, t]);

  return (
    <SharedLayout t={t} setHeight={true}>
      <div style={{
        textAlign: 'center',
        marginTop: '100px',
        marginBottom: '100px',
        color: '#ffffff',
      }}>
        <h1>{t('Thank you!')}</h1>
        <p id="messages" role="alert" aria-live="assertive" style={messageBody ? {display: 'block'} : {}}>
          {messageBody}
        </p>
        <p>{t("You will receive a confirmation from Arana Assistant on WhatsApp shortly.")}</p>
        <AranaButton
          t={t}
          i18n={i18n}
          buttonText={t('Start Arana Assistant')}
          ariaLabel={t('Start Arana Assistant')}
          onClick={() => window.location.href='http://arana.link/return'}
        />
      </div>
    </SharedLayout>
  );
}

export default Completion;