// App.js
import Home from './Home';
import CustomerInfo from './CustomerInfo';
import Payment from './Payment';
import Completion from './Completion';
import LoadingComponent from './LoadingComponent';

import React, { useEffect, useState, useCallback } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import './i18n';
import { useTranslation } from 'react-i18next';

import { loadStripe } from '@stripe/stripe-js';

import axios from 'axios';
import Footer from './Footer';

const App = () => {
  const publishableKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;

  const stripePromise = loadStripe(publishableKey);
  const [isLoading, setIsLoading] = useState(true);
  const [sessionData, setSessionData] = useState(() => {
    const savedSessionData = sessionStorage.getItem('sessionData');
    return savedSessionData ? JSON.parse(savedSessionData) : null;
  });
  const [clientSecret, setClientSecret] = useState(() => {
    const savedClientSecret = sessionStorage.getItem('clientSecret');
    return savedClientSecret ? JSON.parse(savedClientSecret) : null;
  });

  const { t, i18n } = useTranslation();
  
  const fetchSessionData = useCallback(async (uid) => {
    try {
      console.log('Fetching customer data...');
      console.log('UID:', uid);
      const response = await axios.get(`${process.env.REACT_APP_REST_API_URL}/session/${uid}`);
      setSessionData(response.data);
      sessionStorage.setItem('sessionData', JSON.stringify(response.data));
      console.log('Customer data fetched successfully.');
      console.log('Customer data:', response.data);
    } catch (error) {
      console.error('Error fetching customer data:', error);
      setSessionData(null);
    }
  }, [setSessionData]);

  useEffect(() => {
    setIsLoading(false);
  }, []);

  if (isLoading) {
    return <LoadingComponent
      t={t}
      i18n={i18n}
    />;
  }

  return (
    <Router>
      <Routes>
        <Route path="/" element={
          <Home
            t={t}
            i18n={i18n}
            publishableKey={publishableKey}
          />
        } />
        <Route path="/session" element={
          <CustomerInfo
            fetchSessionData={fetchSessionData}
            sessionData={sessionData}
            setClientSecret={setClientSecret}
            publishableKey={publishableKey}
            t={t}
            i18n={i18n}
          />
        } />
        <Route path="/payment" element={
          <Payment
            clientSecret={clientSecret}
            stripePromise={stripePromise}
            sessionData={sessionData}
            t={t}
            i18n={i18n}
          />
        } />
        <Route path="/completion" element={
          <Completion
            stripePromise={stripePromise}
            t={t}
            i18n={i18n}
          />
        } />
      </Routes>
      <Footer t={t}/>
    </Router>
  );
};

export default App;
