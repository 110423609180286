import {
  PaymentElement,
  // LinkAuthenticationElement
} from '@stripe/react-stripe-js'
import { useState } from 'react'
import { useStripe, useElements } from '@stripe/react-stripe-js';

import AranaButton from './AranaButton';

export default function CheckoutForm({ clientSecret, sessionData, t, i18n}) {
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  let paymentMethod = sessionData?.sessionInfo.paymentMethod;

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      setMessage("Stripe.js has not yet loaded.");
      return;
    }

    setIsLoading(true);

    const submitResult = elements.submit();

    if (submitResult.error) {
      // Handle errors in payment submission
      setMessage(submitResult.error.message);
      setIsLoading(false);
      return;
    }

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${window.location.origin}/completion`,
      },
      clientSecret: clientSecret,
      payment_method: (paymentMethod === 'card' ? 'card' : 'sepa_debit')
    });

    setHasError(true);
    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occured.");
    }

    setIsLoading(false);
  }

  return (
    <form id="payment-form" onSubmit={handleSubmit} style={formStyle} aria-label={t('Payment details')}>
      <p style={{
        textAlign: 'center',
        paddingTop: '20px',
        paddingBottom: '20px',
        color: '#ffffff',
        }}
      >
        { 
          !hasError && (
            paymentMethod === 'card' ? t('Please enter your card details below or click on Apple Pay or Google Pay.') :
            paymentMethod === 'sepa_debit' ? t('Please enter your IBAN below. Your other details have been pre-filled.') : ''
          )
        }
      </p>
      {/* <LinkAuthenticationElement id="link-authentication-element"
        // Access the email value like so:
        // onChange={(event) => {
        //  setEmail(event.value.email);
        // }}
        //
        // Prefill the email field like so:
        // options={{defaultValues: {email: 'foo@bar.com'}}}
        /> */}
      <div style={{ width: '80%', maxWidth: '500px' }}>
        <PaymentElement
          id="payment-element"
          options={{
            layout: {
              type: 'accordion',
              defaultCollapsed: false,
              radios: true,
              spacedAccordionItems: false
            },
            defaultValues: {
              billingDetails: {
                name: sessionData.sessionInfo.fullName,
                email: sessionData.sessionInfo.email,
                phone: sessionData.sessionInfo.phoneNumber,
                address: {
                  line1: sessionData.sessionInfo.street,
                  city: sessionData.sessionInfo.city,
                  postal_code: sessionData.sessionInfo.zip,
                  country: sessionData.sessionInfo.country,
                },
              },
            },
            wallets: {
              applePay: (sessionData.sessionInfo.paymentMethod === 'card' ? 'auto' : 'never'),
              googlePay: (sessionData.sessionInfo.paymentMethod === 'card' ? 'auto' : 'never'),
            }
          }}
          style={{
            width: '100%',
            maxWidth: '500px',
          }}
        />
      </div>
      {
        isLoading ? (
          <p
            style={{ color: '#ffffff', paddingTop: '20px', paddingBottom: '20px'}}
            aria-live="assertive" // or "polite" depending on the urgency of the message
            aria-busy={isLoading.toString()}
          >
            {t('Processing your payment. Please wait.')}
          </p>
        ) : (
          <AranaButton
            t={t}
            i18n={i18n}
            buttonText={t('Pay now')}
            ariaLabel={t('Pay now')}
            onClick={handleSubmit}
          />
        )
      }
      {/* Show any error or success messages */
        message &&
          <p style={{
            color: '#ffffff',
          }}
          id="payment-message"
          aria-live="assertive"
          >
            {message}
          </p>
      }
    </form>
  )
}

const formStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
};
