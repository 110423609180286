import React, { useState, useEffect } from 'react';

const SharedLayout = ({ children, t, setHeight }) => {
  const [logoSize, setLogoSize] = useState('400px');

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setLogoSize('300px');
      } else {
        setLogoSize('400px');
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const dynamicCenterStyle = {
    ...centerStyle,
    ...(setHeight ? { height: '100vh' } : {}),
  };

  return (
    <div style={pageStyle}>
      <div style={dynamicCenterStyle}>
        <img
          src={"/arana-pay.png"}
          alt={t('Arana Pay Logo')}
          style={{
            maxWidth: logoSize,
            maxHeight: logoSize,
            marginBottom: '40px',
            marginTop: '40px',
          }}
        />
        {children}
      </div>
    </div>
  );
};

const pageStyle = {
  backgroundColor: '#d03142',
};

const centerStyle = {
  display: 'flex', 
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
};

export default SharedLayout;
