import React from 'react';

function BuyButtonComponent({ subscriptionType, t, i18n }) {

  const browserLanguage = i18n.language || window.navigator.language;

  let buyButtonIDBasic, buyButtonIDPremium, buyButtonIDUnlimited;
  switch (browserLanguage) {
    case 'de':
      buyButtonIDBasic = 'buy_btn_1OKNU1HHNHnS3cnw0UjN7G35';
      buyButtonIDPremium = 'buy_btn_1OKNWCHHNHnS3cnwoiloJ6H8';
      buyButtonIDUnlimited = 'buy_btn_1OKNYlHHNHnS3cnwNCS5xa4F';
      break;
    default:
      // Default IDs if language is not matched
      buyButtonIDBasic = 'buy_btn_1N8LXpHHNHnS3cnwtmMm55PH';
      buyButtonIDPremium = 'buy_btn_1N8La2HHNHnS3cnwoAVfCEeN';
      buyButtonIDUnlimited = 'buy_btn_1N8LaaHHNHnS3cnwgQoaT0P8';
  }

  let buyButtonID;

  if (subscriptionType === 'basic') {
    buyButtonID = buyButtonIDBasic;
  } else if (subscriptionType === 'premium') {
    buyButtonID = buyButtonIDPremium;
  } else if (subscriptionType === 'unlimited') {
    buyButtonID = buyButtonIDUnlimited;
  } else {
    throw new Error('Invalid subscription type');
  }

  return (
    <stripe-buy-button
      buy-button-id={buyButtonID}
      publishable-key="pk_live_51MtFk6HHNHnS3cnw1vJTE9Sv5q4lCD2XVxtl7T401swbMk9dKxzOihlRrx8gvdyw1F0qn2mN7DxzWGhuSPq7kH8q00NOSpqWBX"
      style={{ margin: '30px' }}
    >
    </stripe-buy-button>
  );
}

export default BuyButtonComponent;